import { css } from 'linaria';
import { theme } from '../../../components/Theme';

export const smallSection = css`
  display: flex;
  flex-direction: column;
  width: 30rem;
  max-width: 100%;
  label {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  ${theme.below.sm} {
    width: 100%;
  }
`;

export const activeSegment = css`
  position: relative;
`;
